import { getAllFilesDetails } from "../../../apis/sharedFolderApi";
import { ProjectService } from "../../../service/PegaService";
import LoadingOverlay from "react-loading-overlay-ts";
import React, { useEffect, useState } from "react";
import { changeDateFormat } from "../../../utils";
import { useParams } from "react-router-dom";
import "primeicons/primeicons.css";
import JobList from "./JobList";
import "./index.scss";

function convertJobList(FileDetails) {
  let keyCounter = 1;

  function traverse(job) {
    const newObj = {
      children: [],
      data: job?.Data,
      key: keyCounter++,
    };

    job?.JobNameList?.forEach((jobName, index) => {
      const updatedData = {
        ...jobName?.Data,
        firstParentKey: newObj?.key,
      };
      const childObj = {
        children: [],
        data: updatedData,
        firstParentKey: newObj?.key,
        key: newObj?.key * 10 + index + 1,
      };

      jobName?.FileList?.forEach((file, i) => {
        childObj?.children?.push({
          data: {
            ...file?.Data,
            keyCode: childObj?.key,
            parentKey: newObj?.key,
            Date: file?.Data?.Date && changeDateFormat(file?.Data?.Date),
          },
          parentKey: newObj?.key,
          keyCode: childObj?.key,
          key: childObj?.key * 10 + i + 1,
        });
      });

      newObj?.children?.push(childObj);
    });

    return newObj;
  }

  return FileDetails?.map(traverse);
}

function FilesFolderCompo(props) {
  let columnNamesJson = localStorage.getItem("filesAllColumnNames");
  const [JobListData, setJobListData] = useState([]);
  const [ProjectDetalis, setProjectDetalis] = useState({});
  const columnNames = JSON.parse(columnNamesJson);
  const { view, loader, setLoader } = props;
  let { ProjectID } = useParams();
  if (columnNames === null) {
    const columnNames = ProjectService.getFilesAllColumnNames();
    localStorage.setItem("filesAllColumnNames", JSON.stringify(columnNames));
  }

  useEffect(() => {
    fetchDependencyMappingTabDetails(ProjectID);
  }, []);

  const fetchDependencyMappingTabDetails = async (projectId) => {
    setLoader(true);
    const { response } = await getAllFilesDetails(projectId);
    const data = convertJobList(response?.JobList);
    setProjectDetalis(response?.ProjectDetails);
    setJobListData(data);
    setLoader(false);
  };

  return (
    <>
      {loader ? (
        <LoadingOverlay active={true} spinner text="" />
      ) : (
        <>
          <JobList
            {...props}
            view={view}
            JobListData={JobListData}
            ProjectDetalis={ProjectDetalis}
          />
        </>
      )}
    </>
  );
}

export default FilesFolderCompo;
