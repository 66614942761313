import moment from "moment";
import complete from "../../../assets/images/complete.svg";
import inprogress from "../../../assets/images/inprogress.svg";
import available from "../../../assets/images/available.svg";
import Awaiting from "../../../assets/images/Awaiting.svg";
import Rejected from "../../../assets/images/RejectedIcon.svg";
import { AcpService } from "../../../service/ACPService";

function getMaxDate(arr) {
  // Convert the date strings into Date objects and find the max date
  const maxDate = arr.reduce((max, current) => {
    // Create a Date object for comparison
    const currentDate = new Date(current);
    return currentDate > max ? currentDate : max;
  }, new Date(arr[0]));

  // Convert the max Date object back to the original string format
  const maxDateString = arr.find((date) => new Date(date).getTime() === maxDate.getTime());
  return maxDateString;
}

function getMinDate(arr) {
  // Convert the date strings into Date objects and find the min date
  const minDate = arr.reduce((min, current) => {
    // Create a Date object for comparison
    const currentDate = new Date(current);
    return currentDate < min ? currentDate : min;
  }, new Date(arr[0]));

  // Convert the min Date object back to the original string format
  const minDateString = arr.find((date) => new Date(date).getTime() === minDate.getTime());
  return minDateString;
}

export const getRestructuredData = (apiData) => {
  let mainTempArr = [];
  let tasks = [];
  const designGroup = {
    group: "Design",
    key: "0",
    code: "Design",
    data: {
      Task: "Design",
    },
    children: [],
  };

  const inputGroup = {
    group: "Input",
    key: "1",
    code: "Input",
    data: {
      Task: "Input",
    },
    children: [],
  };

  const FAAssemblyGroup = {
    group: "Final Art",
    key: "2",
    code: "Final Art",
    data: {
      Task: "Final Art",
    },
    children: [],
  };

  tasks = [
    {
      name: "Define DI",
      code: "DDI",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DDI_")),
      group: "Design",
    },
    {
      name: "Upload Approved DI",
      code: "UADI",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UADI_")),
      group: "Design",
    },
    {
      name: "Define RDT",
      code: "DDT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DDT_")),
      group: "Design",
    },
    {
      name: "Upload RDT",
      code: "URDT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("URDT_")),
      group: "Design",
    },
    {
      name: "Approve RDT",
      code: "ARDT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("ARDT_")),
      group: "Design",
    },
    {
      name: "Define Production Ready Art",
      code: "DPRA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DPRA_")),
      group: "Design",
    },
    {
      name: "Upload Production Ready Art",
      code: "UPRA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UPRA_")),
      group: "Design",
    },
    {
      name: "Approve Production Ready Art",
      code: "APRA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("APRA_")),
      group: "Design",
    },
    {
      name: "Prelim Print Feas",
      code: "CPPFA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CPPFA_")),
      group: "Design",
    },
    {
      name: "Define CD/PT",
      code: "DNPF",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DNPF_")),
      group: "Design",
    },
    {
      name: "Confirm CD",
      code: "CCD",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CCD_")),
      group: "Design",
    },
    {
      name: "Confirm PT",
      code: "CPT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CPT_")),
      group: "Design",
    },
    {
      name: "Define Ink Qual",
      code: "DNIQ",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DNIQ_")),
      group: "Design",
    },
    {
      name: "Confirm Ink Qual",
      code: "CNIQ",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CNIQ_")),
      group: "Design",
    },
    {
      name: "AW Scope Alignment",
      code: "SAA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("SAA_")),
      group: "Input",
    },
    {
      name: "Dependency Mapping",
      code: "DM",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("DM_")),
      group: "Input",
    },
    {
      name: "Trigger POA",
      code: "TPOA",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("TPOA_")),
      group: "Input",
    },
    {
      name: "Copy Phase",
      code: "CPC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CPC_")),
      group: "Input",
    },
    {
      name: "Upload Brief Docs",
      code: "UBD",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UBD_")),
      group: "Input",
    },
    {
      name: "Input Rework Task",
      code: "IRT",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("RWK_")),
      group: "Input",
    },
    {
      name: "Request CICs",
      code: "RCIC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("RCIC_")),
      group: "Input",
    },
    {
      name: "Upload CIC",
      code: "UCIC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UCIC_")),
      group: "Input",
    },
    {
      name: "Approve CIC",
      code: "ACIC",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("ACIC_")),
      group: "Input",
    },
    {
      name: "Req CIC Matrix",
      code: "RCICMatrix",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("RCICMatrix_")),
      group: "Input",
    },
    {
      name: "Upload CIC Matrix",
      code: "UCICMatrix",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("UCICMatrix_")),
      group: "Input",
    },
    {
      name: "Approve CIC Matrix",
      code: "ACICMatrix",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("ACICMatrix_")),
      group: "Input",
    },
    {
      name: "Send POA to BVE",
      code: "SPB",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("SPB_")),
      group: "Final Art",
    },
    {
      name: "FA Assembly",
      code: "FAAS",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("FAAS_")),
      group: "Final Art",
    },
    {
      name: "FA approval",
      code: "FAAP",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("FAAP_")),
      group: "Final Art",
    },
    {
      name: "Disp FA to Print",
      code: "CSP",
      data: apiData?.filter((data) => data?.AWM_Task_ID?.includes("CSP_")),
      group: "Final Art",
    },
  ];

  tasks.forEach((task, index) => {
    let tempObj = {};
    if (task.data?.length > 0) {
      if (
        task.data[0]?.AWM_Task_ID.includes("DDI_") ||
        task.data[0]?.AWM_Task_ID.includes("DDT_") ||
        task.data[0]?.AWM_Task_ID.includes("DPRA_") ||
        task.data[0]?.AWM_Task_ID.includes("CPPFA_") ||
        task.data[0]?.AWM_Task_ID.includes("DNPF_") ||
        task.data[0]?.AWM_Task_ID.includes("DNIQ_") ||
        task.data[0]?.AWM_Task_ID.includes("SAA_") ||
        task.data[0]?.AWM_Task_ID.includes("DM_")
      ) {
        // tempObj["key"] = index;
        tempObj["key"] =
          task.data[0]?.GroupName === "Design"
            ? `0-${index}`
            : task.data[0]?.GroupName === "Input"
            ? `1-${index}`
            : `2-${index}`;
        tempObj["code"] = task.data[0]?.AWM_Task_ID;

        let dataObj = {};
        dataObj["Task"] =
          task.data[0]?.IsDefined === false ? task.data[0]?.Task_Type : task.data[0]?.Task_Name;
        dataObj["Task_ID"] = task.data[0]?.AWM_Task_ID;
        dataObj["Dependency"] = task.data[0]?.Dependency;
        dataObj["Project_TaskID"] = task.data[0]?.Project_TaskID;
        dataObj["Project_PredecessorID"] = task.data[0]?.Project_PredecessorID;
        dataObj["Role"] = task.data[0]?.Role;
        dataObj["RoleOptions"] = task.data[0]?.RoleOptions || [];
        dataObj["Assignee"] = task.data[0]?.Assignee;
        dataObj["Owner"] = task.data[0]?.Owner;
        dataObj["State"] = task.data[0]?.State;
        dataObj["TaskDuration"] = task.data[0]?.TaskDuration;
        dataObj["Start_Date"] = task.data[0]?.Start_Date;
        dataObj["End_Date"] = task.data[0]?.End_Date;
        dataObj["Buffer_To_Work"] = task.data[0]?.Buffer_To_Work;
        dataObj["Buffer"] = task.data[0]?.Buffer;
        dataObj["Remaining_Work"] = task.data[0]?.Remaining_Work;
        dataObj["Help_Needed"] = task.data[0]?.Help_Needed;
        dataObj["GroupName"] = task.data[0]?.GroupName;
        dataObj["Days_Left"] = task.data[0]?.Days_Left;
        dataObj["Design_Job_ID"] = task.data[0]?.Design_Job_ID;
        dataObj["Comments"] = task.data[0]?.Comments;
        dataObj["Help_Needed_Reason"] = task.data[0]?.Help_Needed_Reason;
        tempObj["data"] = dataObj;
        tempObj["children"] = [];
        tempObj["redirect"] = true;
      } else {
        let tempArr = [];
        let pStartDate = "";
        let pEndDate = "";
        let startDateArr = [];
        let endDateArr = [];

        tempObj["code"] = task.code;
        // tempObj["key"] = index;
        tempObj["key"] =
          task.data[0]?.GroupName === "Design"
            ? `0-${index}`
            : task.data[0]?.GroupName === "Input"
            ? `1-${index}`
            : `2-${index}`;
        let dataObj = {};
        dataObj["Task"] = `${task.name} (X${task.data?.length})`;
        dataObj["Task_ID"] = task.data[0]?.AWM_Task_ID;
        dataObj["Dependency"] = task.data[0]?.Dependency;
        dataObj["Project_TaskID"] = task.data[0]?.Project_TaskID;
        dataObj["Project_PredecessorID"] = task.data[0]?.Project_PredecessorID;
        dataObj["Role"] = "";
        dataObj["RoleOptions"] = [];
        dataObj["Assignee"] = "";
        dataObj["Owner"] = "";
        dataObj["State"] = "";
        dataObj["TaskDuration"] = "";
        dataObj["Buffer_To_Work"] = task.data[0]?.Buffer_To_Work;
        dataObj["Remaining_Work"] = task.data[0]?.Remaining_Work;
        dataObj["Buffer"] = task.data[0]?.Buffer;
        dataObj["Help_Needed"] = false;
        dataObj["GroupName"] = task.data[0]?.GroupName;
        dataObj["Days_Left"] = task.data[0]?.Days_Left;
        dataObj["Design_Job_ID"] = task.data[0]?.Design_Job_ID;
        dataObj["Comments"] = task.data[0]?.Comments;
        dataObj["Help_Needed_Reason"] = task.data[0]?.Help_Needed_Reason;
        tempObj["data"] = dataObj;
        tempObj["redirect"] = true;

        //child array creation

        task.data.forEach((dt, index) => {
          dt.Start_Date && startDateArr?.push(dt.Start_Date);
          dt.End_Date && endDateArr?.push(dt.End_Date);

          pStartDate = startDateArr.length > 0 && getMinDate(startDateArr);
          pEndDate = endDateArr.length > 0 && getMaxDate(endDateArr);

          let taskStartWith = dt.AWM_Task_ID.charAt(0);
          switch (taskStartWith) {
            case "D":
              taskStartWith = "Define_";
              break;
            case "U":
              taskStartWith = "Upload_";
              break;
            case "A":
              taskStartWith = "Approve_";
              break;
            case "C":
              taskStartWith = "Confirm_";
              break;
            default:
              taskStartWith = "";
              break;
          }
          let tempObj = {};
          tempObj["key"] = index;
          tempObj["code"] = dt.AWM_Task_ID;
          let dataObj = {};

          if (
            dt.AWM_Task_ID.includes("ARDT_") ||
            dt.AWM_Task_ID.includes("ACIC_") ||
            dt.AWM_Task_ID.includes("ACICMatrix_")
          ) {
            dataObj["Task"] = dt.IsDefined === false ? `${dt.Task_Type}` : `${dt.Task_Name}`;
          } else {
            dataObj["Task"] =
              dt.IsDefined === false
                ? `${index + 1}). ${dt.Task_Type}`
                : `${index + 1}). ${dt.Task_Name}`;
          }

          dataObj["Task_ID"] = dt.AWM_Task_ID;
          dataObj["Dependency"] = dt.Dependency;
          dataObj["Project_TaskID"] = dt.Project_TaskID;
          dataObj["Project_PredecessorID"] = dt.Project_PredecessorID;
          dataObj["Role"] = dt.Role;
          dataObj["RoleOptions"] = dt.RoleOptions || [];
          dataObj["Assignee"] = dt.Assignee;
          dataObj["Owner"] = dt.Owner;
          dataObj["State"] = dt.State;
          dataObj["TaskDuration"] = dt.TaskDuration;
          dataObj["Start_Date"] = dt.Start_Date;
          dataObj["End_Date"] = dt.End_Date;
          dataObj["Buffer_To_Work"] = dt.Buffer_To_Work;
          dataObj["Remaining_Work"] = dt.Remaining_Work;
          dataObj["Buffer"] = dt.Buffer;
          dataObj["Help_Needed"] = dt.Help_Needed;
          dataObj["GroupName"] = dt.GroupName;
          dataObj["Days_Left"] = dt.Days_Left;
          dataObj["Design_Job_ID"] = dt.Design_Job_ID;
          dataObj["Comments"] = dt.Comments;
          dataObj["Help_Needed_Reason"] = dt.Help_Needed_Reason;
          tempObj["data"] = dataObj;

          tempObj["children"] = [];
          tempArr.push(tempObj);
        });
        dataObj["Start_Date"] = pStartDate;
        dataObj["End_Date"] = pEndDate;

        if (
          task.data[0]?.AWM_Task_ID.includes("ARDT_") ||
          task.data[0]?.AWM_Task_ID.includes("ACICMatrix") ||
          task.data[0]?.AWM_Task_ID.includes("ACIC_")
        ) {
          // addDependencyInfo(tempArr);
          const groupCount = countGroupOccurrences(tempArr);
          const count = Object.values(groupCount);
          let subObj = [];
          let j = 0;
          let code;

          //sorting the data based on Design_Job_ID for numbering the tasks
          tempArr?.sort((a, b) => {
            const idA = a?.data?.Design_Job_ID;
            const idB = b?.data?.Design_Job_ID;
          
            if (idA < idB) return -1;
            if (idA > idB) return 1;
            return 0;
          });
          tempArr.forEach((data, index) => {
            if (code !== data?.data?.Design_Job_ID) {
              j = 1;
            }
            code = data?.data?.Design_Job_ID;
            // if(data.data.depend !== undefined){
            //   code = data.data.depend[0];
            // } else {
            //   code = data.data.Design_Job_ID;
            // }
            if (!subObj[code]) {
              let i = Object.keys(subObj).length;
              let name = {};
              name["Task"] = task.data[0]?.AWM_Task_ID.includes("ACICMatrix")
                ? `Approve CIC Matrix ${i + 1} (x${count[i]})`
                : task.data[0]?.AWM_Task_ID.includes("ACIC_")
                ? `Approve CIC ${i + 1} (x${count[i]})`
                : `Approve RDT ${i + 1} (x${count[i]})`;

              let newKey = task.data[0]?.AWM_Task_ID.includes("ACICMatrix")
                ? tasks.findIndex((obj) => {
                    return obj.code === "ACICMatrix";
                  })
                : task.data[0]?.AWM_Task_ID.includes("ACIC_")
                ? tasks.findIndex((obj) => {
                    return obj.code === "ACIC";
                  })
                : tasks.findIndex((obj) => {
                    return obj.code === "ARDT";
                  });

              subObj[code] = {
                key: `0-${newKey}-${i}`,
                children: [],
                data: name,
                code: code,
              };
            }
            let arr = {};
            arr["key"] = j++;
            data.data["Task"] = `${j - 1}). ${data.data["Task"]}`;
            arr["data"] = data.data;
            arr["code"] = data.data.Task_ID;
            arr["children"] = [];
            subObj[code].children.push(arr);
          });
          const tempSubArray = Object.values(subObj);
          tempObj["children"] = tempSubArray;
        } else {
          tempObj["children"] = tempArr;
        }
      }

      // Add children to the corresponding group
      if (tempObj.data.GroupName === "Design") {
        designGroup.children.push(tempObj);
      } else if (tempObj.data.GroupName === "Input") {
        inputGroup.children.push(tempObj);
      } else {
        FAAssemblyGroup.children.push(tempObj);
      }
    }
  });
  // Add the Design and Input groups to the mainTempArr
  if (designGroup.children.length > 0) {
    mainTempArr.push(designGroup);
  }

  if (inputGroup.children.length > 0) {
    mainTempArr.push(inputGroup);
  }

  if (FAAssemblyGroup.children.length > 0) {
    mainTempArr.push(FAAssemblyGroup);
  }
  return mainTempArr; //toBeReplacedWithapiData;
};
export const sortBasedOnSelectedValue = (array, sortArray) => {
  return [...array]?.sort(
    (a, b) => sortArray.indexOf(a.field_Name) - sortArray.indexOf(b.field_Name)
  );
};
export const countGroupOccurrences = (data) => {
  const dependencyCounts = {};
  data.forEach((item) => {
    let dependency;
    // if(item.data.depend !== undefined){
    //   dependency = item.data.depend[0];
    // } else {
    //   dependency = item.data.Dependency;
    // }
    dependency = item.data.Design_Job_ID;

    if (dependencyCounts.hasOwnProperty(dependency)) {
      dependencyCounts[dependency]++;
    } else {
      dependencyCounts[dependency] = 1;
    }
  });

  return dependencyCounts;
};

export const addDependencyInfo = (data) => {
  const newData = data.map((item) => {
    const taskId = item.data.Task_ID;
    const dependency = item.data.Dependency;

    if (
      (taskId.startsWith("ARDT_") ||
        taskId.startsWith("ACICMatrix") ||
        taskId.startsWith("ACIC_")) &&
      dependency
    ) {
      const matchingDependencies = data.filter(
        (otherItem) =>
          otherItem.data.Task_ID !== taskId &&
          otherItem.data.Dependency &&
          otherItem.data.Dependency.includes(taskId)
      );

      if (matchingDependencies.length > 0) {
        // Add a "depend" field to each matching dependency with Dependency IDs
        matchingDependencies.forEach((matchingDependency) => {
          if (!matchingDependency.data.depend) {
            matchingDependency.data.depend = [];
          }
          matchingDependency.data.depend.push(dependency);
        });
      }
    }

    return item;
  });

  return newData;
};

export const saveData1 = (
  updatedData,
  activate,
  projectPlanDesignData,
  projectPlanDesign,
  projectState,
  firstTime
) => {
  if (projectState !== "Draft" || firstTime) {
    return saveUpdatedData(updatedData, activate, projectPlanDesignData, projectPlanDesign);
  } else {
    let updatedDataList = projectPlanDesignData?.map(
      ({ AWM_Project_ID, AWM_Task_ID, Assignee, Role, TaskDuration }) => {
        updatedData = {
          AWM_Task_ID: AWM_Task_ID,
          Assignee: Assignee,
          Role: Role,
          TaskDuration: TaskDuration,
        };
        if (!activate) {
          updatedData.AWM_Project_ID = AWM_Project_ID;
        }
        return updatedData;
      }
    );

    // Filter only items where Role and Assignee is not an empty string
    updatedDataList = updatedDataList.filter(
      ({ Role, Assignee }) =>
        (Role !== undefined && Role !== "") || (Assignee !== undefined && Assignee !== "")
    );
    return updatedDataList;
  }
};

export const saveUpdatedData = (
  updatedData,
  activate,
  projectPlanDesignData,
  projectPlanDesign
) => {
  projectPlanDesignData.filter(
    ({ AWM_Project_ID, AWM_Task_ID, Assignee, Role, TaskDuration, Start_Date }) =>
      projectPlanDesign.some((object2) => {
        if (
          AWM_Task_ID === object2.AWM_Task_ID &&
          ((Role !== undefined && Role !== "" && Role !== object2.Role) ||
            (Assignee !== undefined && Assignee !== "" && Assignee !== object2.Assignee) ||
            (TaskDuration !== undefined &&
              TaskDuration !== "" &&
              TaskDuration !== object2.TaskDuration) ||
            (Start_Date !== undefined && Start_Date !== "" && Start_Date !== object2.Start_Date))
        ) {
          if (!activate) {
            updatedData.AWM_Project_ID = AWM_Project_ID;
          }
          const newData = {
            AWM_Task_ID: AWM_Task_ID,
            Assignee: Assignee,
            Role: Role,
            TaskDuration: TaskDuration,
          };
          if (Start_Date !== object2.Start_Date) {
            newData.Start_Date = AcpService.changeDateFromISTtoUTC(new Date(Start_Date));
          }
          updatedData.push(newData);
          return true;
        }
        return false;
      })
  );
  return updatedData;
};
export const formatDate = (date) => {
  const formattedDate = new Date(
    moment(date, "YYYYMMDD[T]HHmmss.SSS [GMT]")
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
  );
  if (formattedDate.getFullYear() > "1999") {
    return formattedDate;
  } else {
    return "";
  }
};

export const formatIntoDatePicker = (date) => {
  const formattedDate = new Date(
    moment(date, "DD-MMM-YY")
      .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)")
  );
  if (formattedDate.getFullYear() > "1999") {
    return formattedDate;
  } else {
    return "";
  }
};

export const formatPayloadDate = (date) => {
  if (date) {
    const dateInGMT = moment.tz(date, "Etc/GMT+0");

    // Add one day to the date before formatting
    dateInGMT.add(1, "days");

    // Format the date with the GMT offset
    const formattedDate = dateInGMT.format("YYYYMMDD[T]HHmmss.SSS [GMT]");
    return formattedDate;
  } else {
    return date;
  }
};

export const daysLeft = (startDate, taskDuration) => {
  let startDateObj = new Date(startDate);
  let endDate = new Date(startDateObj.getTime() + taskDuration * 24 * 60 * 60 * 1000);
  let today = new Date();

  let daysLeft = 0;

  while (today < endDate) {
    // Check if the current day is not a Saturday (6) or Sunday (0)
    if (today.getDay() !== 6 && today.getDay() !== 0) {
      // Increment daysLeft only if it's not a Saturday or Sunday
      daysLeft++;
    }

    // Move to the next day
    today.setDate(today.getDate() + 1);
  }

  return daysLeft;
};

export function extractData(obj, result = []) {
  if (obj.data) {
    const resObj = {
      ...obj.data,
      key: obj.key, // adding key for child parent relationship
    };
    // Add logic to map Project_TaskID and Project_PredecessorID if they exist
    if (obj.data?.Project_TaskID) {
      resObj["Task ID"] = obj.data?.Project_TaskID; // Rename or modify as needed
    }
    if (obj.data?.Project_PredecessorID) {
      resObj["Predecessor"] = obj.data?.Project_PredecessorID; // Rename or modify as needed
    }

    result.push(resObj);
  }

  if (obj.children && obj.children.length > 0) {
    obj.children.forEach((child) => extractData(child, result));
  }

  return result;
}

export const dipsalyStateValues = (data) => {
  switch (data) {
    case "Complete":
    case "Complete-Approved":
    case "Approved":
      return (
        <>
          <img src={complete} alt="Check" className="iconsStyle" />
          <span className="iconsTextStyle" onClick={() => {}}>
            {data}
          </span>
        </>
      );
    case "In-Progress":
      return (
        <>
          <img src={inprogress} alt="In Progress" className="iconsStyle" />
          <span className="iconsTextStyle iconInprogress" onClick={() => {}}>
            {data}
          </span>
        </>
      );
    case "Available":
      return (
        <>
          <img src={available} alt="Lock" className="iconsStyle" />
          <span className="iconsTextStyle iconLock" onClick={() => {}}>
            {data}
          </span>
        </>
      );
    case "Awaiting":
    case "On-Hold":
      return (
        <>
          <img src={Awaiting} alt="Awaiting" className="iconsStyle" />
          <span className="iconsTextStyle iconAwaiting" onClick={() => {}}>
            {data}
          </span>
        </>
      );
    case "Resolved-Cancelled":
    case "Complete-Rejected":
    case "Cancel":
    case "Cancelled":
      return (
        <>
          <img src={Rejected} alt="Rejected" className="iconsStyle" />
          <span className="iconsTextStyle iconRejected" onClick={() => {}}>
            {data}
          </span>
        </>
      );
    default:
      return <>{data}</>;
  }
};
