import React, { useRef } from "react";
import { Toast } from "primereact/toast";
import ProjectListHeader from "../../Projects/MyProjects/ProjectListHeader";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { PlnMassTxt } from "../constant";
import ExportData from "../../ExportData";
import { ProjectService } from "../../../service/PegaService";

const CapacityManagementSubHeader = ({
  handleMessUpPlan,
  isProjectData,
  isTaskData,
  isSearch,
  isSearchSet,
  planningData,
  selectedTask,
  selectedProject,
  activeTable,
  isFilterEnabled,
  setLoader,
  setClearAllFilter,
}) => {
  const toast = useRef(null);

  const columns = planningData?.isTask
    ? ProjectService.getAvailablePlanningColumnNames()
    : ProjectService.getAvailablePlanningProjectColumnNames();
  const fileName = planningData?.isTask ? "Task_List" : "Project_List";
  const newColumns = columns.filter((e) => e !== "SelectAll");
  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const handleMeesUpdate = () => {
    handleMessUpPlan();
  };

  const onClickClearFilter = () => {
    isSearchSet(false);
    setClearAllFilter(true);
    localStorage.removeItem("sortedColumnPlanning");
    localStorage.removeItem("_selectedFieldsRefCurrentPlanning");
  };

  return (
      <div className="projectSetupParent project-plan-wrapper newdiv subHeaderWrapper">
        <Toast ref={toast} />
        <div className="breadCrumbParent">
          <div className="row">
            <div className="col">
              <ProjectListHeader
                clearFilters={onClickClearFilter}
                clearFilter={onClickClearFilter}
                onSearchClick={onSearchClick}
                isFilterEnabled={isFilterEnabled}
                headers={newColumns}
                filterFLag={false}
                CustomizeViewFlag={true}
                ResetToDefaultFlag={true}
              />

              <div className="excelIcon">
                {planningData?.planningData?.length ? (
                  <ExportData
                    allData={planningData?.planningData}
                    selectedRows={
                      selectedTask?.length > 0 || selectedProject?.length > 0
                        ? activeTable === "TaskList"
                          ? selectedTask
                          : selectedProject
                        : activeTable === "TaskList"
                        ? isTaskData
                        : isProjectData
                    }
                    filename={fileName}
                    headers={newColumns}
                  />
                ) : null}
              </div>
              <div className="action-buttons">
                <DropdownButton
                  title="Action"
                  disabled={!selectedTask.length && !selectedProject.length}
                  className="dropdown-button-custom"
                  // id={!actionFlag.length ? "tasksInActive" : "tasksActive"}
                >
                  <React.Fragment>
                    <Dropdown.Item className="text-massUpdate" onClick={handleMeesUpdate}>{PlnMassTxt}</Dropdown.Item>
                  </React.Fragment>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};
export default CapacityManagementSubHeader;
