import moment from "moment";
import { AcpService } from "./ACPService";
import XLSX, { read, utils } from "xlsx";

export const getBuRegionList = (userGroup) => {
  const filteredData = getValidBUList(userGroup);
  const buRegionList = filteredData?.map((obj) => {
    return {
      label: `${obj?.UserBU[0]?.BU_Name} - ${obj?.UserRegion[0]?.Region_Name}`,
      value: `${obj?.UserBU[0]?.BU_Name} - ${obj?.UserRegion[0]?.Region_Name}`,
    };
  });
  const list = removeDuplicates(buRegionList);
  return list;
};

export const getValidBUList = (userGroup) => {
  const filteredData = userGroup?.filter((item) =>
    item?.UserRole.some((role) => role?.roleId.toLowerCase() === "capacitymanager")
  );
  const filteredOnes = removeDuplicatesByBUAndRegion(filteredData);
  return filteredOnes;
};

const removeDuplicates = (data) => {
  const seen = new Set();
  return data?.filter((item) => {
    const duplicate = seen?.has(item?.label?.toLowerCase());
    seen?.add(item?.label?.toLowerCase());
    return !duplicate;
  });
};

const removeDuplicatesByBUAndRegion = (data) => {
  const uniqueKeys = new Set();  // A set to store unique 'BU-Region' combinations

  return data?.filter(item => {
      const bu = item?.UserBU[0]?.BU_Name;  // Extract BU Name
      const region = item?.UserRegion[0]?.Region_Name;  // Extract Region Name
      const key = `${bu}-${region}`;  // Create a unique key based on BU and Region
      
      // If the key hasn't been seen yet, add it to the set and keep the item
      if (!uniqueKeys.has(key)) {
          uniqueKeys.add(key);  // Mark this combination as seen
          return true;  // Keep this item
      }
      return false;  // Otherwise, it's a duplicate, filter it out
  });
}


export const getSelectedBuList = (userGroup, selectedBuRegion) => {
  const selectedBuList = userGroup?.filter((item) => {
    const selected = selectedBuRegion?.label?.split("-");
    const buName = selected[0]?.trim();
    const region = selected[1]?.trim();
    return item?.UserBU[0]?.BU_Name === buName && item?.UserRegion[0]?.Region_Name === region;
  });
  const filteredArray = removeDuplicatesByBUAndRegion(selectedBuList);
  return filteredArray;
};

export const removeAllLocalStorage = (keysToRemove) => {
  if (keysToRemove) {
    keysToRemove?.forEach((k) => localStorage?.removeItem(k));
  } else {
    const items = [
      "sortedColumnBooking",
      "sortedColumnPlanning",
      "sortedColumnDashboardPanelTop",
      "sortedColumnDashboardPanelBottom",
      "_selectedFieldsRefCurrentBooking",
      "_selectedFieldsRefCurrentPlanning",
      "_selectedFieldsRefCurrentDashboardPanelTop",
      "_selectedFieldsRefCurrentDashboardPanelBottom",
    ];
    items?.forEach((k) => localStorage?.removeItem(k));
  }
};

export const createPayloadData = (rtaData) => {
  const finalData = [];
  rtaData?.forEach((el) => {
    let ob = {
      TaskName: el?.TaskName,
      TaskType: el?.TaskType,
      POANumber: el?.POANumber,
      Slack: el?.Slack,
      EstimatedStartDate: AcpService.getConvertedDate(el?.NewStartDate),
      EstimatedEndDate: "",
      Duration: el?.Duration,
      TaskStatus: el?.Status,
    };
    finalData.push(ob);
  });
  return finalData;
};

export const returnValidDate = (date) => {
  const validDate = moment(date, "YYYY-MM-DDTHH:mm:ss.SSS[Z]", true).isValid();
  return validDate;
};

export const creatMassUpdatePayload = (massUpdateData) => {
  const updatedData = massUpdateData?.map((el) => {
    const isNotValidFormat = returnValidDate(el?.StartDate);
    return {
      AWMTaskID: el?.AWMTaskID,
      Start_Date: isNotValidFormat
        ? moment(el?.StartDate).utc().format("YYYYMMDDTHHmmss.SSS [GMT]")
        : el?.StartDate,
    };
  });
  return updatedData;
};

export const bookingRtaPayload = (rtaData, tabName) => {
  const submitRows = [];
  rtaData?.forEach((row) => {
    const isNotValidFormat = returnValidDate(row?.StartDate);
    let temp = {
      TaskName: row?.TaskName ? row?.TaskName : row?.Task_Name,
      TaskType: row?.TaskType ? row?.TaskType : row?.Task_Type,
      POANumber: row?.POANumber,
      Slack: row?.Slack,
      Duration: row?.Duration,
      TaskStatus: row?.Status,
    };
    if (tabName === "booking") {
      temp["EstimatedStartDate"] = isNotValidFormat
        ? moment(row?.StartDate).utc().format("YYYYMMDDTHHmmss.SSS [GMT]")
        : row?.StartDate;
      temp["EstimatedEndDate"] = "";
    } else if (tabName === "bveLoop") {
      temp["EstimatedEndDate"] = row?.EndDate;
      temp["EstimatedStartDate"] = "";
    }
    submitRows.push(temp);
  });
  return submitRows;
};

export const jsonToXLConverter = (input, fileName, sheetName) => {
  var reportWS = XLSX.utils.json_to_sheet(input);
  var wb = XLSX.utils.book_new(); // make Workbook of Excel
  XLSX.utils.book_append_sheet(wb, reportWS, sheetName); // sheetAName is name of Worksheet
  XLSX.writeFile(wb, fileName + ".xlsx");
};

export const xlToJSONConverter = (uploadedFile, callback) => {
  if (!uploadedFile) return;
  let ext = uploadedFile.name;
  ext = ext.split(".");
  ext = ext[ext.length - 1];
  let reader = new FileReader();
  reader.onloadend = async function () {
    let arrayBuffer = reader.result;
    let options = { type: "array" };
    let workbook = read(arrayBuffer, options);
    let sheetName = Array.isArray(workbook.SheetNames)
      ? workbook.SheetNames[0]
      : workbook.SheetNames;
    let sheet = workbook.Sheets[sheetName];
    let sheet_to_json = utils.sheet_to_json(sheet);
    let results;
    if (sheet_to_json.length === 0) {
      let sheet_to_csv = [utils.sheet_to_csv(sheet)];
      results = sheet_to_csv;
    }
    if (sheet_to_json.length > 0) {
      results = sheet_to_json;
    }

    let parsedFileObj = {
      results: results,
      ext: ext,
    };
    callback(parsedFileObj);
  };
  reader.readAsArrayBuffer(uploadedFile);
};

export const excelDateToJSDate = (serial, format) => {
  if (isNaN(serial) || serial <= 0) return null; // Check if the serial is not a number or non-positive
  const utc_days = Math.floor(serial - 25569);
  const time = (serial % 1) * 86400;
  const date = new Date((utc_days * 86400 + time) * 1000);
  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return null;
  }

  if (!AcpService.isUBN(format) && format) {
    return AcpService.changeDateFromISTtoUTC(date);
  }
  // Format the date as ISO string with time zone
  return date.toISOString();
};
const searchValue = (allValue, searchValue, columnName) => {
  return allValue.find((item) => item[columnName] && item[columnName] === searchValue);
};

const getObjectFromArray = (dataArray, referenceArray, columnName) => {
  if (!dataArray) return [];
  return dataArray
    .split(",")
    .map((item) => searchValue(referenceArray, item, columnName))
    .filter((item) => item !== undefined);
};

export const createPayload = (
  importData = [],
  smos = [],
  brands = [],
  category = [],
  scales = [],
  projectTypeList = [],
  supplierList = []
) => {
  if (Array.isArray(importData) && importData.length === 0) return null;
  const result = {
    ArtworkAgilityProjects: [],
  };
  importData?.forEach((projectData) => {
    const smosArray = projectData["SMO"];
    const smo = getObjectFromArray(smosArray, smos, "SMO_Name");

    const brandsArray = projectData["Brand"];
    const brand = getObjectFromArray(brandsArray, brands, "Brand_Name");

    const categoryArray = projectData["Category"];
    const categoryValue = getObjectFromArray(categoryArray, category, "Category_Name");

    const scaleArray = projectData["Scale"];
    const scaleValueArray = getObjectFromArray(scaleArray, scales, "Scale_Name");
    const scaleValue = scaleValueArray[0]?.Scale_Name || "";

    const supplierListArray = projectData["Artwork Studio"];
    const supplierListValueArray = getObjectFromArray(
      supplierListArray,
      supplierList,
      "Supplier_Name"
    );
    const supplierListValue = supplierListValueArray[0]?.Supplier_Name || "";

    const projectTypeListArray = projectData["Project type"];
    const projectTypeListValueArray = getObjectFromArray(
      projectTypeListArray,
      projectTypeList,
      "ProjectType_Name"
    );
    const projectTypeListValue = projectTypeListValueArray[0]?.ProjectType_Name || "";

    result.ArtworkAgilityProjects.push({
      Project_Name: projectData["Project Name"],
      Initiative_Group_Name: projectData["Initiative Group Name"],
      Project_Description: projectData["Project Description"],
      BU: projectData["BU"],
      Project_region: projectData["Region"],
      Cluster: projectData["Cluster"],
      Project_Scale: scaleValue,
      Project_Type: projectTypeListValue,
      IL: projectData["IL"],
      PM: projectData["PM"],
      Estimated_No_Of_POAs: projectData["# of POAs"],
      Estimated_No_Of_CICs: projectData["# of CICs"],
      Estimated_No_Of_DT: projectData["# of RDTs"],
      Project_Start_Date: excelDateToJSDate(projectData["Project Start date"]),
      Estimated_AW_Readiness: excelDateToJSDate(projectData["AW readiness date"]),
      Estimated_AW_Printer: excelDateToJSDate(projectData["AW@Printer date"]),
      Estimated_SOS: excelDateToJSDate(projectData["Estiamted SOS"]),
      Estimated_SOP: excelDateToJSDate(projectData["Estimated SOP"]),
      Production_Strategy: projectData["Production Strategy"],
      Tier: projectData["Tier"],
      Company: supplierListValue,
      Artwork_Category: categoryValue,
      Artwork_Brand: brand,
      Artwork_SMO: smo,
    });
  });

  return result;
};
export const sortByGroup = (data) => {
  const groupOrder = {
    BookingAlerts2weeks: 1,
    MediumTerm2to8weeks: 2,
    LongTermMoreThanTwoMonths: 3,
  };
  return data?.sort((a, b) => groupOrder[a?.Group] - groupOrder[b?.Group]);
};

export const addGroupInfo = (data) => {
  let result = [];
  for (const key in data) {
    if (Array.isArray(data[key])) {
      let groupInfo = {};
      if (key === "BookingAlerts") {
        groupInfo = { Group: "BookingAlerts2weeks", GroupOrder: 1 };
      } else if (key === "MediumTerm") {
        groupInfo = { Group: "MediumTerm2to8weeks", GroupOrder: 2 };
      } else if (key === "LongTerm") {
        groupInfo = { Group: "LongTermMoreThanTwoMonths", GroupOrder: 3 };
      }
      const updatedEntries = data[key]?.map((entry) => ({
        ...entry,
        ...groupInfo,
      }));
      result = result.concat(updatedEntries);
    }
  }
  return result;
};

export function removeKey(obj, keyToRemove) {
  return Object.keys(obj).reduce((acc, key) => {
    if (key !== keyToRemove) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

// Helper function to get the start and end weeks for a given month
const getWeeksInMonth = (startDate) => {
  const start = new Date(startDate);
  const end = new Date(start.getFullYear(), start.getMonth() + 1, 0);
  const weeks = new Set();

  // Iterate through each day of the month
  for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
    const weekNumber = getWeekNumber(d);
    weeks.add(weekNumber);
  }

  return weeks;
};

// Helper function to calculate week number from a date
const getWeekNumber = (date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(((date - startOfYear) / 86400000 + startOfYear.getDay() + 1) / 7);
  return weekNumber;
};

export const transformTasks = (inputArray, firstDayOfMonth) => {
  const result = [];
  const weekMap = {};

  // Get the valid weeks for the given month
  const validWeeks = getWeeksInMonth(firstDayOfMonth);

  // Process the input tasks
  if (inputArray.length > 0) {
    inputArray?.forEach((item) => {
      const week = item.CalendarWeek;
      if (!weekMap[week]) {
        weekMap[week] = {
          CalendarWeek: week,
          KickedPOA: 0,
          ActivePOA: 0,
        };
      }
      if (item.Task === "Kicked POA's") {
        weekMap[week].KickedPOA += item.Capacity;
      } else if (item.Task === "Active POA's") {
        weekMap[week].ActivePOA += item.Capacity;
      }
    });
  }

  // Include weeks with no data but within the valid range
  validWeeks?.forEach((week) => {
    const formattedWeek = `CW ${week.toString().padStart(2, "0")}`;
    if (!weekMap[formattedWeek]) {
      weekMap[formattedWeek] = {
        CalendarWeek: formattedWeek,
        KickedPOA: 0,
        ActivePOA: 0,
      };
    }
  });

  // Convert weekMap to result array
  for (const week in weekMap) {
    result.push(weekMap[week]);
  }

  // Sort the result by CalendarWeek (in numerical order)
  result?.sort((a, b) => {
    const weekNumberA = parseInt(a.CalendarWeek.replace("CW ", ""), 10);
    const weekNumberB = parseInt(b.CalendarWeek.replace("CW ", ""), 10);
    return weekNumberA - weekNumberB;
  });

  return result;
};

export const fillMissingDates = (inputData, month, year) => {
  // Initialize a map to hold the summaries based on date
  const summaryMap = {};
  // Create a list of all dates in the specified month and year
  const daysInMonth = new Date(year, month, 0).getDate(); // Total days in the month
  for (let day = 1; day <= daysInMonth; day++) {
    const dateString = `${year}-${month?.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    summaryMap[dateString] = {
      Date: dateString,
      "FA Assembly": 0,
      "FA Assembly SerialNumber": [],
      "Upload CIC": 0,
      "Upload CIC SerialNumber": [],
    };
  }

  // Process the input data
  inputData?.forEach((entry) => {
    const { Date, TaskType, Capacity, SerialNumber } = entry;

    if (summaryMap[Date]) {
      // Update the corresponding totals and serial numbers
      if (TaskType === "FA Assembly") {
        summaryMap[Date]["FA Assembly"] = Capacity;
        summaryMap[Date]["FA Assembly SerialNumber"].push(SerialNumber);
      } else if (TaskType === "Upload CIC") {
        summaryMap[Date]["Upload CIC"] = Capacity;
        summaryMap[Date]["Upload CIC SerialNumber"].push(SerialNumber);
      }
    }
  });

  // Convert the summary map to an array
  const summaryArray = Object.values(summaryMap);

  // Format the output as desired
  const output = summaryArray?.map((item) => ({
    Date: item?.Date,
    "FA Assembly": item["FA Assembly"],
    "FA Assembly SerialNumber": item["FA Assembly SerialNumber"].join(", ") || "",
    "Upload CIC": item["Upload CIC"],
    "Upload CIC SerialNumber": item["Upload CIC SerialNumber"].join(", ") || "",
  }));

  return output;
};

export const getStartAndEndOfMonth = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = date.getMonth(); // Months are 0-indexed (0 = January, 11 = December)

  const startDate = new Date(year, month, 1);

  const endDate = new Date(year, month + 1, 0); // The 0th day of the next month gives the last day of the current month

  return {
    startDate: AcpService.changeDateFromISTtoUTC(startDate),
    endDate: AcpService.changeDateFromISTtoUTC(endDate),
  };
};
