import { AcpService } from "./ACPService";

export const AcpCommonService = {
  removeEmptyArraysWithNoData(obj) {
    const result = {};

    for (let key in obj) {
      if (!(Array.isArray(obj[key]) && obj[key].length === 0)) {
        result[key] = obj[key];
      }
    }

    return result;
  },
  isFieldBlankOrSingle(selectedField, selectedColumnName) {
    // Check if selectedField is null, undefined, an empty string, or an empty object
    if (!selectedField || (selectedField && Object.keys(selectedField).length === 0)) {
      return true;
    }

    // Check if the object has exactly one key and if that key matches the selectedColumnName
    const keys = Object.keys(selectedField);
    if (keys && keys.length === 1 && keys[0] === selectedColumnName) {
      const value = selectedField[selectedColumnName];

      // Check if the value is an array (regardless of how many elements it has)
      return Array.isArray(value);
    }

    // If more than one key is present, or the key doesn't match selectedColumnName, return false
    return false;
  },
  handleAllowDate({ selectedDate, setData, startDate, setSataus }) {
    setData((prevData) => {
      let isFormValidOrNot = false;
      const allTasks = prevData?.map((item) => {
        const isAllow =
          new Date(AcpService.convertToIST(selectedDate)) <
          new Date(AcpService.convertToIST(item[startDate]))
            ? false
            : true;
        item.allow = isAllow;
        if (!isAllow) {
          isFormValidOrNot = true;
        }
        return item;
      });
      setSataus(isFormValidOrNot);
      return allTasks;
    });
  },

  handleAllowDatePlanning({ selectedDate, setData, startDate, setSataus }) {
    setData((prevData) => {
      let isFormValidOrNot = false;
      const allTasks = prevData?.map((item) => {
        const isAllow =
          new Date(AcpService.convertToIST(selectedDate)) <
          new Date(AcpService.convertFormatToUTC(item[startDate]))
            ? false
            : true;
        item.allow = isAllow;
        if (!isAllow) {
          isFormValidOrNot = true;
        }
        return item;
      });
      setSataus(isFormValidOrNot);
      return allTasks;
    });
  },

  checkMinDate(selectedData, setMinDate) {
    let minDate = new Date();
    if (selectedData.length > 0) {
      minDate = selectedData?.reduce((p, q) => {
        const PDate = new Date(AcpService.convertToIST(p?.StartDate));
        const QDate = new Date(AcpService.convertToIST(q?.StartDate));
        return PDate.getTime() < QDate.getTime() ? p : q;
      });
      minDate = new Date(AcpService.convertToIST(minDate?.StartDate));
    }
    setMinDate(minDate);
  },
  checkMinDatePlanning(selectedData, setMinDate) {
    let minDate = new Date();
    if (selectedData.length > 0) {
      minDate = selectedData?.reduce((p, q) => {
        const PDate = new Date(AcpService.convertFormatToUTC(p?.StartDate));
        const QDate = new Date(AcpService.convertFormatToUTC(q?.StartDate));
        return PDate.getTime() < QDate.getTime() ? p : q;
      });
      minDate = new Date(AcpService.convertFormatToUTC(minDate?.StartDate));
    }
    setMinDate(minDate);
  },
  updateQueryParams(key, value, location, navigate) {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has(key)) {
      searchParams.set(key, value);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  },
  parseCustomDate(dateString) {
    // Extract components of the date string
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6) - 1; // JavaScript months are 0-based
    const day = dateString.substring(6, 8);
    const hours = dateString.substring(9, 11);
    const minutes = dateString.substring(11, 13);
    const seconds = dateString.substring(13, 15);

    return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
  },

  checkValidProjectReadinessDate(selectedDate, selectedProjectData) {
    let isFormValidOrNot = false;
    const modifiedProjectData = selectedProjectData?.map((item) => {
      const printerDate = item?.EstimatedAWPrinter
        ? AcpService.formatDate(item?.EstimatedAWPrinter)
        : new Date();
      const minDate = item?.ProjectStartDate ? AcpService.getProjectMinDate(item) : new Date();
      if (selectedDate > printerDate || selectedDate < minDate) {
        item["isAllow"] = false;
        isFormValidOrNot = true;
      } else {
        item["isAllow"] = true;
      }
      return item;
    });
    return {
      formValid: isFormValidOrNot,
      projectData: modifiedProjectData,
    };
  },
  convertArrayToObject(inputArray) {
    // Initialize an empty object to store the combined result
    const result = {};

    // Iterate through each object in the input array
    inputArray?.forEach((item) => {
      // Get each key-value pair in the current object
      for (const key in item) {
        if (item?.hasOwnProperty(key)) {
          // If the key does not exist in the result, add it and assign the value
          if (!result[key]) {
            result[key] = item?.[key];
          } else {
            // If the key already exists in the result, concatenate the values
            result[key] = result?.[key]?.concat(item[key]);
          }
        }
      }
    });

    return result;
  },
  getUniqueValuesObject(obj) {
    // Iterate through each key in the object
    for (const key in obj) {
      // Convert the array to a Set to remove duplicates, then back to an array
      obj[key] = [...new Set(obj[key])];
    }

    return obj;
  },

  sortDates(dateStrings) {
    const normalDates = [];
    const dateRanges = [];

    // Separate normal dates and date ranges
    dateStrings?.forEach((dateString) => {
      if (dateString?.includes("to")) {
        dateRanges.push(dateString);
      } else {
        normalDates.push(dateString);
      }
    });

    // Function to parse date strings into Date objects
    const parseDate = (dateString) => {
      const [day, month, year] = !AcpService.isUBN(dateString) && dateString?.split("-");
      const monthIndex = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ].indexOf(month);
      return new Date(`20${year}`, monthIndex, day); // Assuming 20xx for years
    };

    // Sort normal dates
    normalDates?.sort((a, b) => parseDate(a) - parseDate(b));

    // Sort date ranges based on the start date
    dateRanges?.sort((a, b) => {
      const startA = parseDate(a?.split(" to ")[0]);
      const startB = parseDate(b?.split(" to ")[0]);
      return startA - startB;
    });

    // Combine sorted normal dates and date ranges
    return [...normalDates, ...dateRanges];
  },
  sortTasksByDate(tasks, direction) {
    return tasks?.sort((a, b) => {
      // Function to extract the date for sorting
      const extractDate = (task) => {
        const dateString = task?.Date.includes(" to ") ? task?.Date.split(" to ")[0] : task.Date;
        return new Date(dateString);
      };

      const dateA = extractDate(a);
      const dateB = extractDate(b);

      // Compare dates according to the specified direction
      if (direction === "asc") {
        return dateA - dateB;
      } else if (direction === "desc") {
        return dateB - dateA;
      } else {
        throw new Error("Invalid sorting direction. Use 'ascending' or 'descending'.");
      }
    });
  },
  getMonthDay(dateString) {
    // date should be in YYYYMMDD
    // Ensure the input is exactly 8 characters and only contains digits
    if (typeof dateString === 'string') {
        const extractedMonth = dateString.slice(4, 6); // Extract characters at index 4-5 for the month
        const day = dateString.slice(6, 8);   // Extract characters at index 6-7 for the day
        const month = parseInt(extractedMonth, 10);
        return { month, day};
    } else {
        throw new Error('Invalid date format. Please use YYYYMMDD.');
    }
  },
  findUniqueByKey(arr, key) {
    const uniqueValues = new Set(); // To keep track of unique values for the specified key
    return arr?.filter((obj) => {
      if (!uniqueValues.has(obj[key])) {
        uniqueValues.add(obj[key]);
        return true; // Keep this object in the result
      }
      return false; // Exclude duplicate key values
    });
  },
};
