import {
    fileSizeErrorMessage,
    fileTypeErrorMessage,
    defaultAllowedFileType,
    defaultAllowedFileSize,
} from '../constants/FileUploadConstant';
import { formatBytes } from './FileUploadService';

export const isFileValid = (selectedFile, accept, maxSize, acceptType=defaultAllowedFileType,errorType=fileTypeErrorMessage) => {
    let isValid = true;
    let errorMessage = '';
    let acceptedFileType=accept || acceptType;
    if (Array.isArray(acceptedFileType)) {
        acceptedFileType = acceptedFileType.join(', ');
    }
    const maxFileSize = maxSize || defaultAllowedFileSize;
    if (selectedFile) {
        if (
            acceptedFileType === '*' ||
            acceptedFileType.includes(selectedFile.type.split('/')[1].toLowerCase())
        ) {
            if (selectedFile.size <= maxFileSize) {
                isValid = true;
                errorMessage = '';
                return [isValid, errorMessage];
            } else {
                isValid = false;
                errorMessage = fileSizeErrorMessage(formatBytes(maxFileSize));
                return [isValid, errorMessage];
            }
        } else {
            isValid = false;
            errorMessage =  errorType;
            return [isValid, errorMessage];
        }
    } else {
        isValid = false;
        errorMessage =  "";
        return [isValid, errorMessage];
    }
};
