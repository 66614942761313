/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useState, useEffect, useRef, Suspense, useMemo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProjectService } from "../../service/PegaService";
import ArtworkStatusListHeader from "./ArtworkStatusListHeader";
import { getArtworkStatusList } from "../../apis/artworkStatusApi";
// import { getArtworkStatusList } from "../../store/actions/ArtworkStatusActions";
import { multiFilterChange } from "../../utils";
import _ from "lodash";
import ArtworkStatusProjectNameHeader from "./ArtworkStatusProjectNameHeader";
import { cicImage, artworkStatusExportCSVFlags, getStatusFlags } from "./utils";
import { getProjectValues } from "../../store/actions/ProjectSetupActions";
import AlertIcon from "../common/AlertIcon/AlertIcon";
import { dsbpConstant } from "../DSBP/constant";
import { checkIfGreyIcon, checkIfRedIcon } from "../DSBP/utils";
import Footer from "../common/tableFooter/tableFooter";
import { isString } from "lodash";
import {
  hallowGreyCircle,
  hallowRedCircle,
  inProgressLate,
  redCross,
  greenV,
  hallowGreenCircle,
  solidGreenCircle,
} from "./constant";
import { formatDate } from "../Projects/ProjectPlan/util";
import ACPFilter from "../ACP/common/ACPFilter";
import { AcpService } from "../../service/ACPService";
import { AcpCommonService } from "../../service/AcpCommonService";

const FullKitConditionPopUp = lazy(() => import("./FullKitConditionPopUp"));

const ArtworkStatusList = (props) => {
  const colAllSelected = useRef([]);
  const selectedFieldsRef = useRef([]);
  const [pegadata, setPegaData] = useState(null);
  const [isCICAddded, setIsCICAddded] = useState(false);
  const [initpegadata, setInitPegaData] = useState(null);
  const [ProjectFrozen, setProjectFrozen] = useState(false);
  const [frozenCoulmns, setFrozenColumn] = useState([]);
  const [selectedColumnName, setSelectedColumnName] = useState(null);
  const [projectColumnName, setProjectColumnNames] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filteredDataExport, setFilteredDataExport] = useState([]);
  const [visible, setVisible] = useState(false);
  const [sortData, setSortData] = useState([]);
  const [allColumnNamesArtworkStatus, setAllColumnNames] = useState([]);
  const [updatedAllColumnNames, setUpdatedAllColumnNames] = useState([]);
  const [isSearch, isSearchSet] = useState(false);
  const [isReorderedColumn, setReorderedColumn] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isColWidthSet, setColWidth] = useState(null);
  const [customViewIsAddedMyProject, isCustomViewIsAddedArtworkStatus] = useState(false);
  const [openFullKitpopUp, setOpenFullkitPopUp] = useState(false);
  const [filterCleared, setFilterCleared] = useState(false);
  const [rowFullkitData, setRowFullkitData] = useState(null);
  const [fieldName, setFieldName] = useState(null);
  const [pmpList, setPMPList] = useState([]);
  const [artworkStatus, artworkStatusList] = useState([]);
  const [initialColumnLength, setinitialColumnLength] = useState({});
  const [customizeViewFields, setCustomizeViewFields] = useState(
    localStorage.getItem("customizeViewArtworkStatusFields")
  );
  const [resizeMode, setResizeMode] = useState("fit");
  const [conditionMatched, setConditionMatched] = useState(false);

  const headerName = "Artwork Status";

  const { loading } = false;
  let { ProjectID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filterArray = useRef([]);
  const selectedColumnList = useRef([]);
  const currentUrl = useLocation().pathname.split("/");
  const { userInformation } = useSelector((state) => state.UserReducer);

  let isCIC = isCICAddded;

  const onSearchClick = () => {
    isSearchSet(!isSearch);
  };

  const op = useRef(null);
  const dt = useRef(null);

  const onSort = (column, direction, filterData) => {
    const sortedData = filterData?.length ? filterData : filters?.length ? filters : pegadata;
    const sortedDataSet = AcpService.sortDataFirstStringThenNumeric(sortedData, column, direction);
    const finalSortedData = _.cloneDeep(sortedDataSet);

    setSortData([column, direction]);
    localStorage.setItem("sortingDataArtworkStatus", JSON.stringify(true));
    if (filterData?.length) {
      setFilters(finalSortedData);
      selectedColumnList.current = finalSortedData;
      setFilteredDataExport(finalSortedData);
    } else {
      setPegaData(finalSortedData);
    }
  };

  useEffect(() => {
    dispatch(getProjectValues(ProjectID));
    const columnNamesInitial = ProjectService.getAllColumnNames();
    setinitialColumnLength({
      availableFields: 0,
      freezedColumns: 0,
      selectedFields: columnNamesInitial.length,
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const updatedUsers = await getArtworkStatusList(ProjectID);
        const pmpList = updatedUsers?.PMPList || [];
        setInitPegaData(_.cloneDeep(pmpList));
        setIsCICAddded(updatedUsers?.CICs);
        artworkStatusList(updatedUsers);
        setPegaData(pmpList);
        setFilteredDataExport(pmpList);
        setPMPList(pmpList);
      } catch (error) {
        console.error(error);
        // Handle errors here if needed
      }
      setLoader(false);
    };
    fetchData();
  }, [ProjectID]);

  useEffect(() => {
    const ProjectData = _.cloneDeep(pmpList);
    let allCol = [];
    if (ProjectData.length) {
      allCol = Object.keys(ProjectData[0]);
    }
    if (!isCIC) {
      allCol = allCol.filter((item) => item !== "AWM_CICNeeded");
    }
    if (isCIC && !allCol.includes("AWM_CICNeeded")) allCol.splice(10, 0, "AWM_CICNeeded");

    let columnWidthArtworkStatus = {
      PIMaterialDescription: 200,
      POADescription: 200,
    };

    // if (allCol.length) {
    //   allCol.forEach((column) => {
    //     columnWidthArtworkStatus[column] = 200;
    //   });
    // }

    let getJsonStoredWidthColumns = localStorage.getItem("columnWidthArtworkStatus");
    let getStoredWidthColumns = JSON.parse(getJsonStoredWidthColumns);
    const checkEmptyObject = _.isEmpty(getStoredWidthColumns);

    if (checkEmptyObject) {
      if (!isCIC) {
        getStoredWidthColumns = getStoredWidthColumns?.filter((item) => item !== "AWM_CICNeeded");
      }
      if (isCIC && !getStoredWidthColumns?.includes("AWM_CICNeeded"))
        getStoredWidthColumns?.splice(10, 0, "AWM_CICNeeded");

      localStorage.setItem("columnWidthArtworkStatus", JSON.stringify(columnWidthArtworkStatus));
    }
    let jsonColWidth = localStorage.getItem("isColWidthSetArtworkStatus");
    let isColWidthSetFlag = JSON.parse(jsonColWidth);
    if (isColWidthSetFlag) {
      setColWidth(true);
    }
  }, [pmpList]);

  const reorderColumns = (columns) => {
    const requiredColumnOrderArray = ProjectService.getAllColumnNames();
    let reorderedColumns = [];
    requiredColumnOrderArray.forEach((rcl) => {
      columns.forEach((cl) => {
        if (rcl.Field_Name === cl) {
          reorderedColumns.push(cl);
        }
      });
    });
    return reorderedColumns;
  };

  useEffect(() => {
    setCustomizeViewFields(customizeViewFields);
  }, [customizeViewFields]);


  useEffect(() => {
    const parsedData =
      customizeViewFields !== "" && customizeViewFields?.length !== 0
        ? JSON.parse(customizeViewFields)
        : [];

    const selectedData = parsedData?.selectedFields?.fieldsData || [];
    const frozenData = parsedData?.freezedColumns?.fieldsData || [];
    const totalColumns = [...selectedData, ...frozenData];

    if (totalColumns.length < 7) {
      setResizeMode("fit");
    } else {
      setResizeMode("expand");
    }
  }, [customizeViewFields]);


  useEffect(() => {
    (async () => {
      try {
        //below is api call
        const ProjectData = _.cloneDeep(pmpList);

        if (ProjectData.length) {
          let allCol = Object.keys(ProjectData[0]);
          if (!isCIC) {
            allCol = allCol.filter((item) => item !== "AWM_CICNeeded");
          }
          if (isCIC && !allCol.includes("AWM_CICNeeded")) allCol.splice(10, 0, "AWM_CICNeeded");

          if (!allCol.includes("Design")) allCol.splice(9, 0, "Design");

          if (!allCol.includes("FA")) allCol.splice(12, 0, "FA");

          setAllColumnNames(reorderColumns(allCol));
        }

        let filteredPegaDataJson = localStorage.getItem("columnWiseFilterDataArtworkStatus");
        const filteredPegaData = JSON.parse(filteredPegaDataJson);

        if (filteredPegaData && filteredPegaData.length) {
          setFilters(filteredPegaData);
          setFilteredDataExport(filteredPegaData);
          // setSelectedFields(filteredPegaData);
          // setPegaData(ProjectData);
          // setInitPegaData(ProjectData);
        }
        // else setPegaData(ProjectData);

        // according to pathname we need to call api and store column name in local storage
        let columnNamesJson = localStorage.getItem("allColumnNamesArtworkStatus");
        const columnNames = JSON.parse(columnNamesJson);
        const fieldToAdd = { Field_Name: "AWM_CICNeeded" };
        if (columnNames != null && columnNames.length) {
          if (isCIC && !columnNames.some((item) => item.Field_Name === fieldToAdd.Field_Name)) {
            columnNames.splice(15, 0, fieldToAdd);
          }
          // columnNames.splice(10, 0, "AWM_CICNeeded");
          setProjectColumnNames(columnNames);
          setUpdatedAllColumnNames(columnNames);
        } else {
          let columnNames = ProjectService.getAllColumnNames();
          if (!isCIC) {
            columnNames = columnNames.filter((item) => item.Field_Name !== "AWM_CICNeeded");
          }
          // columnNames.splice(10, 0, "AWM_CICNeeded");
          if (isCIC && !columnNames.some((item) => item.Field_Name === fieldToAdd.Field_Name)) {
            columnNames.splice(15, 0, fieldToAdd);
          }

          localStorage.setItem("allColumnNamesArtworkStatus", JSON.stringify(columnNames));
          setProjectColumnNames(columnNames);
          setUpdatedAllColumnNames(columnNames);
        }
        // get sort data from local storage and add in state
        let jsonSortingData1 = localStorage.getItem("sortingDataArtworkStatus");
        const sortingDataArtworkStatus = JSON.parse(jsonSortingData1);

        if (sortingDataArtworkStatus && sortingDataArtworkStatus.length) {
          const sortedData = [...ProjectData].sort((a, b) => {
            return a[sortingDataArtworkStatus[0]] > b[sortingDataArtworkStatus[0]] ? 1 : -1;
          });

          if (sortingDataArtworkStatus[1] === "desc") {
            sortedData.reverse();
          }
          setPegaData(sortedData);
          setSortData([sortingDataArtworkStatus[0], sortingDataArtworkStatus[1]]);
        }

        //get frozen data from local storage and add in state
        let jsonFrozenrData1 = localStorage.getItem("frozenDataArtworkStatus");
        const frozenDataArtworkStatus = JSON.parse(jsonFrozenrData1);
        if (frozenDataArtworkStatus && frozenDataArtworkStatus.length) {
          setFrozenColumn(frozenDataArtworkStatus);
        }
      } catch (err) {
        console.log("error", err);
      }
    })();
  }, [pmpList]);

  const formatFilterData = (data) => {
    const formattedData = {
      ...data,
    };
    formattedData["DSBP InitiativeID"] = data?.DSBP_InitiativeID;
    formattedData["DSBP Initiative Name"] = data?.DSBP_Initiative_Name;
    formattedData["RTA ProjectID"] = data?.RTA_ProjectID;
    formattedData["RTA Project Name"] = data?.RTA_Project_Name;
    formattedData["B|W"] = data?.BufferToWork;
    formattedData["B:W"] = data?.BufferToWorkRatio;
    formattedData["CIC"] = data?.CICFlag;
    formattedData["FA"] = data?.FAFlag;
    formattedData["Country Of Sales"] = data?.CountryOfSales;
    formattedData["FA Approval (A/A/R)"] = data?.FAApproval;
    formattedData["Design"] = data?.DesignFlag;
    formattedData["FA Assembly"] = data?.FAAssemblyState;
    formattedData["Group"] = data?.AWM_GroupPMP;
    formattedData["PMP State"] = data?.PMPState;
    formattedData["POA Description"] = data?.POADescription;
    formattedData["POA Number"] = data?.POANumber;
    formattedData["POA Status"] = data?.POAStatus;
    formattedData["TD State"] = data?.TDState;
    formattedData["Copy"] = data?.ApprovedCopyElements + "/" + data?.TotalCopyElements;
    formattedData["ETA"] = !isString(data?.POAReleaseDate)
      ? formatDate(data?.POAReleaseDate)
      : data?.POAReleaseDate;
    formattedData["Req"] = !isString(data?.AWPrinterDate)
      ? formatDate(data?.AWPrinterDate)
      : data?.AWPrinterDate;

    return formattedData;
  };

  const addFrozenColumns = (name) => {
    if (!frozenCoulmns.includes(name)) {
      frozenCoulmns.push(name);
      setFrozenColumn(frozenCoulmns);
    } else {
      let columnIndex = frozenCoulmns.indexOf(name);
      frozenCoulmns.splice(columnIndex, 1);
      setFrozenColumn(frozenCoulmns);
    }
  };

  const projectNameOnClick = (e, options) => {
    setFilterCleared(false);
    op.current.toggle(e);
    setSelectedColumnName(options);
  };

  const StatusFlags = ({ state }) => {
    const { statusFlags, text } = getStatusFlags({ state }); // Pass state as an object
    return (
      <div>
        <span
          className={statusFlags}
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "8rem",
            fontSize: "1rem",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          {text}
        </span>
      </div>
    );
  };

  const fullKitReadinessBody = (options, rowData) => {
    let field = rowData.field;

    const showRedIcon = checkIfRedIcon(options);
    const showGreyIcon = checkIfGreyIcon(options);
    const showAlertIcon = showRedIcon || showGreyIcon;
    return (
      <>
        {/* {field === 'SI.No' && <span title={rowData.rowIndex + 1}>{rowData.rowIndex + 1}</span>} */}
        {field === "FAAssemblyState" && <StatusFlags state={options.FAAssemblyState} />}
        {field === "FAApproval" && <span>{options.FAApproval}</span>}
        {field === "CountryOfSales" && (
          <span title={options.CountryOfSales}>{options.CountryOfSales}</span>
        )}
        {field === "ApprovedCopyElements" && (
          <span title={options.ApprovedCopyElements + "/" + options.TotalCopyElements}>
            {options.ApprovedCopyElements + "/" + options.TotalCopyElements}
          </span>
        )}
        {field === "POAReleaseDate" && <span title={options[field]}>{options[field]}</span>}
        {field === "AWPrinterDate" && <span title={options[field]}>{options[field]}</span>}
        {field === "FA" && (
          <div
            className="cic-tooltip-target"
            title={artworkStatusExportCSVFlags(options["FAFlag"])}
          >
            {options["FAFlag"] === "NA" ? (
              <div>{options["FAFlag"]}</div>
            ) : options["FAFlag"]?.includes("|") ? (
              <>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOpenFullkitPopUp(true);
                    setRowFullkitData(options);
                    setFieldName("fa");
                  }}
                >
                  {options["FAFlag"]}
                </div>
              </>
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setOpenFullkitPopUp(true);
                  setRowFullkitData(options);
                  setFieldName("fa");
                }}
              >
                <div
                  onClick={() => {
                    if (options["FAFlag"] === "GreenV") {
                      navigate(`/${currentUrl[1]}/projectPlan/${ProjectID}`);
                    } else {
                      setOpenFullkitPopUp(true);
                      setRowFullkitData(options);
                      setFieldName("fa");
                    }
                  }}
                >
                  <div className="footerIconsMain">
                    <div>
                      <i className={`footerIconAll ${cicImage(options["FAFlag"])}`}></i>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {field === "Design" && (
          <div
            className="cic-tooltip-target"
            title={artworkStatusExportCSVFlags(options["DesignFlag"])}
          >
            {options["DesignFlag"] === "NA" ? (
              <div title={artworkStatusExportCSVFlags(options["DesignFlag"])}>
                {" "}
                {options["DesignFlag"]}
              </div>
            ) : (
              <div className="footerIconsMain">
                <div title={artworkStatusExportCSVFlags(options["DesignFlag"])}>
                  <i className={`${cicImage(options["DesignFlag"])}`}></i>
                </div>
              </div>
            )}
          </div>
        )}

        {field === "AWM_CICNeeded" && (
          <div className="cic-img">
            <div
              className="cic-tooltip-target"
              title={artworkStatusExportCSVFlags(options["CICFlag"])}
            >
              {options["CICFlag"] === "NA" || options["CICFlag"] === "" || !options["CICFlag"] ? (
                <div title={artworkStatusExportCSVFlags(options["CICFlag"])}>
                  {" "}
                  {options["CICFlag"]}
                </div>
              ) : options["CICFlag"]?.includes("|") ? (
                <>
                  <div
                    title={artworkStatusExportCSVFlags(options["CICFlag"])}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenFullkitPopUp(true);
                      setRowFullkitData(options);
                      setFieldName("cic");
                    }}
                  >
                    {options["CICFlag"]}
                  </div>
                </>
              ) : (
                <>
                  <div
                    title={artworkStatusExportCSVFlags(options["CICFlag"])}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOpenFullkitPopUp(true);
                      setRowFullkitData(options);
                      setFieldName("cic");
                    }}
                  >
                    <div className="footerIconsMain">
                      <div title={artworkStatusExportCSVFlags(options["CICFlag"])}>
                        <i className={`${cicImage(options["CICFlag"])}`}></i>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}

        {field === "PIMaterialNumber" && (
          <>
            <div className="snapshot-icons" disabled={props?.userHasAccess}>
              <span title={options[field]} className="text-option">
                {options[field]}
              </span>
            </div>
            <AlertIcon
              iconType="error"
              showAlertIcon={showAlertIcon}
              onClick={() => {
                navigate(`/${currentUrl[1]}/artworkStatus/ChangeNotification/${ProjectID}`, {
                  state: {
                    from: "artworkstatusPage",
                    assignee: userInformation?.userid,
                    InitiativeID: options?.DSBP_InitiativeID,
                    PIMaterialID: options?.PIMaterialID,
                    PIMaterialNumber: options?.PIMaterialNumber,
                  },
                });
              }}
              editable={showGreyIcon}
              tooltipClass="tooltip-white"
              tooltipText={
                showGreyIcon
                  ? dsbpConstant.xml_awaiting_response_enovia
                  : dsbpConstant.xml_view_changes
              }
              tooltipPosition={"top"}
            />
          </>
        )}

        {/* {field === "POAStatus" && <span>{options.RTA_RTAPOAStatus}</span>} */}

        {field !== "Artwork_Status" &&
          field !== "ApprovedCopyElements" &&
          field !== "POAReleaseDate" &&
          field !== "AWM_CICNeeded" &&
          field !== "AWPrinterDate" &&
          field !== "CICFlag" &&
          field !== "FAFlag" &&
          field !== "FAAssemblyState" &&
          field !== "CountryOfSales" &&
          field !== "FAApproval" &&
          field !== "PIMaterialNumber" &&
          // field !== 'POAStatus' &&
          field !== "DesignFlag" &&
          field !== "Design" &&
          field !== "FA" && (
            <>
              {" "}
              <span title={options[field]}>{options[field]}</span>{" "}
            </>
          )}
      </>
    );
  };

  const costomizeFrozenData = () => {
    let jsonValue;
    if (customizeViewFields !== undefined && customizeViewFields?.length !== 0) {
      jsonValue = customizeViewFields ? JSON.parse(customizeViewFields) : null;
    }
    let freezedData;
    if (jsonValue && Object.keys(jsonValue)?.length !== 0) {
      freezedData = jsonValue?.freezedColumns?.fieldsData || [];
      freezedData && setFrozenColumn(freezedData);
      localStorage.setItem("frozenDataArtworkStatus", JSON.stringify(freezedData));
    }
    return freezedData;
  };

  useEffect(() => {
    costomizeFrozenData();
    if (customizeViewFields && customizeViewFields === "[]") {
      setFrozenColumn([]);
      localStorage.setItem("frozenDataArtworkStatus", JSON.stringify({}));
    }
    const parsedData =
      customizeViewFields !== "" && customizeViewFields?.length !== 0
        ? JSON.parse(customizeViewFields)
        : [];
    let selectedData = parsedData?.selectedFields?.fieldsData;
    if (
      Object.keys(initialColumnLength).length > 0 &&
      selectedData?.length > 0 &&
      initialColumnLength?.selectedFields !== selectedData?.length
    ) {
      isCustomViewIsAddedArtworkStatus(true);
    } else {
      isCustomViewIsAddedArtworkStatus(false);
    }
  }, [customizeViewFields]);

  const dynamicColumns = useMemo(() => {
    let jsonColumnWidthMyProject = localStorage.getItem("columnWidthArtworkStatus");
    const columnWidthArtworkStatus = JSON.parse(jsonColumnWidthMyProject);
    let checkWidth = [];
    if (columnWidthArtworkStatus) {
      checkWidth = Object.keys(columnWidthArtworkStatus);
    }

    const uniqueArray = [...new Set(projectColumnName)];

    let jsonValue;
    if (customizeViewFields !== undefined && customizeViewFields?.length !== 0) {
      jsonValue = customizeViewFields ? JSON.parse(customizeViewFields) : null;
    }

    if (jsonValue && Object.keys(jsonValue).length !== 0) {
      let selectedData = jsonValue?.selectedFields?.fieldsData || [];
      let freezedData = jsonValue?.freezedColumns?.fieldsData || [];
      const mergedData = [...freezedData, ...selectedData];

      const filteredColumns = [];
      // Add freezedData columns in the specified order
      // freezedData && setFrozenColumn(freezedData);
      mergedData?.forEach((fieldName) => {
        const column = uniqueArray?.find((col) => col.Field_Name === fieldName);
        if (column) {
          filteredColumns.push(column);
        }
      });
      if (filteredColumns && filteredColumns.length) {
        return filteredColumns?.map((ele, i) => {
          return (
            <Column
              key={ele.Field_Name}
              field={ele.Field_Name}
              filterField={ele.Field_Name}
              header={ArtworkStatusProjectNameHeader(
                ele.Field_Name,
                frozenCoulmns,
                sortData,
                projectNameOnClick,
                selectedFields
              )}
              columnKey={ele.Field_Name || i}
              frozen={frozenCoulmns?.includes(ele.Field_Name)}
              alignFrozen="left"
              className={frozenCoulmns?.includes(ele.Field_Name) ? "font-bold frozen-width" : ""}
              filter
              showFilterMenu={false}
              filterPlaceholder={ele.Field_Name}
              body={fullKitReadinessBody}
            // {... checkWidth.includes(ele.Field_Name) && }
            // style={{
            //   width: returnColumnWidth(
            //     columnWidthArtworkStatus[ele.Field_Name],
            //     ele.Field_Name,
            //     columnWidthArtworkStatus
            //   ),
            // }}
            />
          );
        });
      }
    } else {
      if (uniqueArray && uniqueArray?.length) {
        return uniqueArray?.map((ele, i) => {
          if (!isCIC && ele.Field_Name === "AWM_CICNeeded") {
            // Skip rendering the column
            return null;
          }

          return (
            <Column
              key={ele.Field_Name}
              field={ele.Field_Name}
              filterField={ele.Field_Name}
              header={ArtworkStatusProjectNameHeader(
                ele.Field_Name,
                frozenCoulmns,
                sortData,
                projectNameOnClick,
                selectedFields
              )}
              columnKey={ele.Field_Name || i}
              frozen={frozenCoulmns?.includes(ele.Field_Name)}
              alignFrozen="left"
              className={frozenCoulmns?.includes(ele.Field_Name) ? "font-bold frozen-width" : ""}
              filter
              showFilterMenu={false}
              filterPlaceholder={ele.Field_Name}
              body={fullKitReadinessBody}
            // {... checkWidth.includes(ele.Field_Name) && }
            // style={{
            //   width: returnColumnWidth(
            //     columnWidthArtworkStatus[ele.Field_Name],
            //     ele.Field_Name,
            //     columnWidthArtworkStatus
            //   ),
            // }}
            />
          );
        });
      }
    }
  }, [projectColumnName, frozenCoulmns, sortData, projectNameOnClick]);

  const clearFilters = () => {
    const allColumnNamesArtworkStatus = ProjectService.getAllColumnNames();
    // const ProjectData = _.cloneDeep(pmpList);
    // let allCol = [];
    // if (ProjectData.length) {
    //   allCol = Object.keys(ProjectData[0]);
    // }
    // let columnWidthArtworkStatus = {};
    let columnWidthArtworkStatus = {
      PIMaterialDescription: 200,
      POADescription: 200,
    };
    // if (allCol.length) {
    //   allCol.forEach((column) => {
    //     columnWidthArtworkStatus[column] = 200;
    //   });
    // }
    setProjectColumnNames(allColumnNamesArtworkStatus);
    localStorage.setItem(
      "allColumnNamesArtworkStatus",
      JSON.stringify(allColumnNamesArtworkStatus)
    );
    localStorage.setItem("columnWiseFilterDataArtworkStatus", JSON.stringify({}));
    const costomizeFrozenData1 = costomizeFrozenData();

    if (costomizeFrozenData1 === undefined) {
      setFrozenColumn([]);
      localStorage.setItem("frozenDataArtworkStatus", JSON.stringify({}));
    }
    localStorage.setItem("sortingDataArtworkStatus", JSON.stringify({}));
    localStorage.setItem("columnWidthArtworkStatus", JSON.stringify(columnWidthArtworkStatus));
    localStorage.removeItem("isColWidthSetArtworkStatus");
    localStorage.setItem("frozenDataArtworkStatus", JSON.stringify({}));
    selectedFieldsRef.current = [];
    setColWidth(false);
    setSelectedFields([]);
    setSortData([]);
    setReorderedColumn(false);
    setFilters([]);
    setFilteredDataExport(initpegadata);
    setVisible(false);
    setFilterCleared(true);
    setPegaData(initpegadata);
    isSearchSet(false);
    setCustomizeViewFields([]);
    setFrozenColumn([]);
    setProjectFrozen(false);
    setResizeMode("expand");
  };

  const onGlobalFilterChange = (e, colName) => {
    const { value, multiplesFlag, arr } = multiFilterChange(
      e,
      colName,
      selectedColumnName,
      selectedFields,
      setSelectedFields,
      pegadata,
      filterArray,
      selectedColumnList,
      colAllSelected,
      selectedFieldsRef
    );
    const _selectedFieldsRef = selectedFieldsRef?.current.map((el, i) => {
      if (Object.keys(arr)?.[0] === Object.keys(el)?.[0]) {
        return {
          ...el,
          [`${selectedColumnName}`]: arr[selectedColumnName],
        };
      } else {
        return el;
      }
    });
    if (AcpService.areAllPropertiesEmptyArrays(_selectedFieldsRef)) {
      localStorage.removeItem("_selectedFieldsRefCurrentArtworkStatus");
    }
    if (filterArray.current?.length) {
      if ((value?.length > 0 && !multiplesFlag) || colAllSelected.current?.length >= 1) {
        const _selectedFieldsRefWithoutEmptyArray =
          AcpService.removeEmptyArrays(_selectedFieldsRef);
        const _selectedFieldsRefWithUniqueObjects = AcpService.getUniqueObjects(
          _selectedFieldsRefWithoutEmptyArray
        );
        const filterData = AcpService.filterDataByfilters(
          pegadata,
          _selectedFieldsRefWithUniqueObjects,
          setConditionMatched,
          "artworkStatus"
        );

        const convertedObj = AcpCommonService.convertArrayToObject(
          _selectedFieldsRefWithoutEmptyArray
        );
        const uniqueObj = AcpCommonService.getUniqueValuesObject(convertedObj);
        setSelectedFields(uniqueObj);
        setFilteredDataExport(filterData);
        if (filterData?.length && sortData?.length) {
          onSort(sortData[0], sortData[1], filterData);
        } else {
          setFilters(filterData);
          selectedColumnList.current = filterData;
        }
      } else {
        selectedColumnList.current = [];
        selectedFieldsRef.current = [];
        setFilteredDataExport([]);
        setSelectedFields({});
        setFilters([]);
      }
    } else {
      selectedColumnList.current = [];
      selectedFieldsRef.current = [];
      setFilteredDataExport([]);
      setSelectedFields({});
      setFilters([]);
    }
  };

  const onColumnResizeEnd = (event) => {
    let columnWidthArtworkStatus = {};
    let jsonColumnWidthMyProject = localStorage.getItem("columnWidthArtworkStatus");
    if (jsonColumnWidthMyProject) {
      columnWidthArtworkStatus = JSON.parse(jsonColumnWidthMyProject);
    }
    columnWidthArtworkStatus[event.column.props.field] = event.element.offsetWidth;

    localStorage.setItem("columnWidthArtworkStatus", JSON.stringify(columnWidthArtworkStatus));
    localStorage.setItem("isColWidthSetArtworkStatus", JSON.stringify(true));
    setColWidth(true);
    setProjectColumnNames(projectColumnName);
    setVisible(false);
  };

  const saveSettings = () => {
    localStorage.setItem("columnWiseFilterDataArtworkStatus", JSON.stringify(filters));
    localStorage.setItem("frozenDataArtworkStatus", JSON.stringify(frozenCoulmns));
    localStorage.setItem("sortingDataArtworkStatus", JSON.stringify(sortData));
    localStorage.setItem("allColumnNamesArtworkStatus", JSON.stringify(projectColumnName));
  };
  // not used currently
  // const saveAsPersonaliDefault = (selectedCategories) => {
  //   const columnNames = JSON.stringify(reorderColumns(selectedCategories));
  //   localStorage.setItem('allColumnNamesArtworkStatus', columnNames);
  //   setProjectColumnNames(reorderColumns(selectedCategories));
  //   if (selectedCategories.length > 9) {
  //     localStorage.setItem('isCustomViewIsAddedArtworkStatus', JSON.stringify(true));
  //     isCustomViewIsAddedArtworkStatus(true);
  //   }
  //   if (selectedCategories.length === 0) {
  //     isCustomViewIsAddedArtworkStatus(true);
  //   }
  //   setVisible(false);
  // };

  // const resetToPgDefault = async () => {
  //   const allColumnNamesArtworkStatus = ProjectService.getAllColumnNames();
  //   localStorage.setItem('allColumnNamesArtworkStatus', JSON.stringify({}));
  //   localStorage.setItem(
  //     'allColumnNamesArtworkStatus',
  //     JSON.stringify(allColumnNamesArtworkStatus)
  //   );
  //   localStorage.removeItem('isCustomViewIsAddedArtworkStatus');
  //   isCustomViewIsAddedArtworkStatus(true);
  //   setProjectColumnNames(allColumnNamesArtworkStatus);
  //   setVisible(false);
  //   // setTableRender(true);
  // };

  const storeReorderedColumns = (e) => {
    const dragColumnName = projectColumnName[e?.dragIndex];
    const index = projectColumnName.indexOf(dragColumnName);
    if (index > -1) {
      // only splice array when item is found
      projectColumnName.splice(index, 1); // 2nd parameter means remove one item only
      projectColumnName.splice(e?.dropIndex, 0, dragColumnName);
      setReorderedColumn(true);
    }
    localStorage.setItem("allColumnNamesArtworkStatus", JSON.stringify(projectColumnName));
    setProjectColumnNames(projectColumnName);
    // setTableRender(!tableRender);
  };

  const clearFilter = () => {
    const costomizeFrozenData1 = costomizeFrozenData();
    localStorage.setItem("columnWiseFilterDataArtworkStatus", JSON.stringify({}));
    localStorage.setItem("sortingDataArtworkStatus", JSON.stringify({}));
    if (costomizeFrozenData1 === undefined) {
      setFrozenColumn([]);
      localStorage.setItem("frozenDataArtworkStatus", JSON.stringify({}));
    }
    selectedFieldsRef.current = [];
    setSelectedFields([]);
    setSortData([]);
    setFilters([]);
    setFilteredDataExport(initpegadata);
    setPegaData(initpegadata);
    isSearchSet(false);
    // setTableRender(!tableRender);
  };

  const clearColumnWiseFilter = () => {
    let jsonFrozenItem = localStorage.getItem("frozenDataArtworkStatus");
    const frozenItem = JSON.parse(jsonFrozenItem);
    let _selectedColumnName = selectedColumnName;

    if (frozenItem && frozenItem?.length && frozenItem.includes(_selectedColumnName)) {
      const index = frozenItem.indexOf(_selectedColumnName);
      frozenItem.splice(index, 1);
      setFrozenColumn(frozenItem);
      localStorage.setItem("frozenDataArtworkStatus", JSON.stringify(frozenItem));
    }
    if (frozenCoulmns.includes(_selectedColumnName)) {
      const index = frozenCoulmns.indexOf(_selectedColumnName);
      frozenCoulmns.splice(index, 1);
      setFrozenColumn(frozenCoulmns);
      setProjectFrozen(!ProjectFrozen);
      const availableColumnNamesData = JSON.parse(customizeViewFields)?.availableFields?.fieldsData;
      const freezedColumnsNamesData = frozenCoulmns?.filter((el) => el !== "SelectAll");
      const selectedFieldsNames = JSON.parse(
        customizeViewFields
      )?.selectedFields?.fieldsData?.filter((el) => el !== "SelectAll");
      let selectedFieldsNamesData = [...selectedFieldsNames, _selectedColumnName];
      const finalData = {
        availableFields: {
          fieldsData: availableColumnNamesData,
          id: "availableFields",
          title: "Available Fields",
        },
        freezedColumns: {
          fieldsData: freezedColumnsNamesData,
          id: "freezedColumns",
          title: "freezed Columns",
        },
        selectedFields: {
          fieldsData: selectedFieldsNamesData,
          id: "selectedFields",
          title: "selected Fields",
        },
      };
      setCustomizeViewFields(JSON.stringify(finalData));
      localStorage.setItem("customizeViewArtworkStatusFields", JSON.stringify(finalData));
    }
    if (sortData && sortData?.length && sortData[0] === _selectedColumnName) {
      localStorage.removeItem("sortingDataArtworkStatus");
      props?.setClearAllFilter(true);
      setSortData([]);
    }

    const selectedFields2 = _.cloneDeep(selectedFields);
    const uniqueObj = AcpCommonService.getUniqueValuesObject(selectedFields2);
    const _selectedFields = AcpService.emptyProperty(uniqueObj, _selectedColumnName);
    const __selectedFields = AcpCommonService.removeEmptyArraysWithNoData(_selectedFields);
    selectedFieldsRef.current = __selectedFields;
    setSelectedFields(__selectedFields);
    if (AcpService.areAllPropertiesEmptyArrays(__selectedFields)) {
      localStorage.removeItem("_selectedFieldsRefCurrentArtworkStatus");
    }
    let data = [...(pegadata || [])];
    Object.keys(__selectedFields).forEach((el) => {
      if (__selectedFields[el]?.length) {
        data = data.filter((ele) => __selectedFields[el].includes(ele[el]));
      }
    });
    if (data?.length && Object.keys(__selectedFields)?.length) {
      setFilters(data);
      setFilteredDataExport(data)
    } else {
      const finalInitData = _.cloneDeep(initpegadata);
      selectedColumnList.current = finalInitData;
      selectedFieldsRef.current = [];
      setPegaData(finalInitData);
      setFilteredDataExport(finalInitData);
      setSelectedFields({});
      setFilters([]);
    }
    setConditionMatched(false);
  };

  const isFilterEnabled =
    (customizeViewFields && customizeViewFields === "[]" && frozenCoulmns?.length) ||
    filters?.length ||
    sortData?.length ||
    isSearch;

  const isResetEnabled =
    isReorderedColumn || isFilterEnabled || isColWidthSet || customViewIsAddedMyProject;

  const timestamp = new Date().getTime();

  const artworkstatusfooter = (
    <div>
      <div className="footerIcons">
        <div className="footerIconsMain">
          <div>
            <i className="pi pi-info footerIconAll footerIcons2"></i>
          </div>
          <div className="footerIconsContent1">Snapchange alert is true</div>
        </div>
        <div className="footerIconsMain">
          <div>
            <i className="pi pi-check footerIconAll footerIcons3"></i>
          </div>
          <div className="footerIconsContent1">{greenV}</div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-times footerIconAll footerIcons4"></i>
          </div>
          <div className="footerIconsContent1">{redCross}</div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-off footerIconAll footerIcons5"></i>
          </div>
          <div className="footerIconsContent1">{hallowGreenCircle}</div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-off footerIconAll footerIcons6"></i>
          </div>
          <div className="footerIconsContent1">
            <span> {hallowRedCircle}</span>
          </div>
        </div>

        <div className="footerIconsMain ">
          <div>
            <i className="pi pi-circle-off footerIconAll footerIcons7"></i>
          </div>
          <div className="footerIconsContent1">
            <span>{hallowGreyCircle}</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-on footerIconAll footerIcons8"></i>
          </div>
          <div className="footerIconsContent1">
            <span style={{ paddingTop: "10px" }}>{solidGreenCircle}</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div>
            <i className="pi pi-circle-on footerIconAll footerIcons9"></i>
          </div>
          <div className="footerIconsContent1">
            <span>{inProgressLate}</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div className="footerIcons10 footerIconAll">
            <span>2/1/0</span>
          </div>
          <div className="footerIconsContent1">
            <span>Approvers / Approved / Rejected</span>
          </div>
        </div>

        <div className="footerIconsMain">
          <div className="footerIcons11 footerIconAll">
            <span>N/A</span>
          </div>
          <div className="footerIconsContent1">
            <span>Design: No design stage for this FA [or] CIC: CIC Needed = 'No' or 'NA'</span>
          </div>
        </div>
      </div>
      <div className="recordfooter">
        {!loader && filteredDataExport && (
          <Footer
            className="footer"
            totalObjects={filteredDataExport?.length}
            isSelected={false}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="artworkStatus">
      {loader || loading || pegadata === null ? (
        <LoadingOverlay active={true} spinner text="" />
      ) : (
        <Suspense fallback={<LoadingOverlay active={true} spinner text="" />}>
          {pegadata !== undefined && (
            <ArtworkStatusListHeader
              header={artworkStatus?.ProjectName}
              clearFilters={clearFilters}
              clearFilter={clearFilter}
              setVisible={setVisible}
              saveSettings={saveSettings}
              onSearchClick={onSearchClick}
              isFilterEnabled={isFilterEnabled}
              isResetEnabled={isResetEnabled}
              allData={conditionMatched ? [] : filters?.length ? filters : pegadata}
              headers={allColumnNamesArtworkStatus}
              filterFLag={false}
              CustomizeViewFlag={false}
              ResetToDefaultFlag={false}
              isTreeTableFlag={false}
              AWPrinterDate={artworkStatus?.AWPrinterDate}
              projectColumnName={projectColumnName}
              headerName={headerName}
              customizeViewFields={customizeViewFields}
              setCustomizeViewFields={setCustomizeViewFields}
              selected={conditionMatched ? [] : filters?.length && filters}
            />
          )}
          <ACPFilter
            op={op}
            onSort={onSort}
            filters={filters}
            sortData={sortData}
            projectData={pegadata}
            setFilters={setFilters}
            setSortData={setSortData}
            pageName={"artworkStatus"}
            saveSettings={saveSettings}
            ProjectFrozen={ProjectFrozen}
            frozenCoulmns={frozenCoulmns}
            selectedFields={selectedFields}
            selectAllColumnNotIncluded={true}
            setFrozenColumn={setFrozenColumn}
            setProjectFrozen={setProjectFrozen}
            addFrozenColumns={addFrozenColumns}
            selectedColumnName={selectedColumnName}
            onGlobalFilterChange={onGlobalFilterChange}
            clearColumnWiseFilter={clearColumnWiseFilter}
          />

          <DataTable
            ref={dt}
            // rows={100}
            scrollable
            resizableColumns
            className="mt-3"
            reorderableColumns
            dataKey="PIMaterialID"
            // tableStyle={{
            //   minWidth: "100%",
            //   width: "max-content",
            // }}
            columnResizeMode={resizeMode}
            // paginatorPosition="bottom"
            footer={artworkstatusfooter}
            filterDisplay={isSearch && "row"}
            onColReorder={storeReorderedColumns}
            onColumnResizeEnd={onColumnResizeEnd}
            // paginator={pegadata?.length || filters?.length}
            key={filterCleared ? `"PIMaterialID" + ${timestamp}` : ""}
            value={conditionMatched ? [] : filters?.length ? filters : pegadata}
          >
            {dynamicColumns}
          </DataTable>
          <div hidden={filters?.length ? filters?.length === 0 : pegadata?.length === 0}>
            <FullKitConditionPopUp
              openFullKitpopUp={openFullKitpopUp}
              setOpenFullkitPopUp={setOpenFullkitPopUp}
              rowFullkitData={rowFullkitData}
              setRowFullkitData={setRowFullkitData}
              fieldName={fieldName}
              artworkStatus={artworkStatus}
            />
          </div>
        </Suspense>
      )}
    </div>
  );
};
export default ArtworkStatusList;
