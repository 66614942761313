import { Loading } from "../../../utils";
import ExportSelectedRows from "../../ExportCSV";
import React, { useState, useEffect } from "react";
import filter from "../../../assets/images/filter.svg";
import BlueFilterIcon from "../../../assets/images/BlueFilterIcon.svg";
import searchMyProjects from "../../../assets/images/searchMyProjects.svg";
import exapandCollapseAll from "../../../assets/images/expandCollapseAll.svg";
import searchMyProjectsClicked from "../../../assets/images/searchMyProjectsClicked.svg";

const FilesFolderHeader = ({
  header,
  allData,
  headers,
  filename,
  clearFilter,
  selectedRows,
  expandedKeys,
  onSearchClick,
  isTreeTableFlag,
  isFilterEnabled,
  projectPlanView,
  setClearGlobalFilter,
  isGlobalFilterActivated,
  setGlobalFilterActivated,
  removeAllFiltersSortAndFreeze,
  handleExapandCollapseAllButton,
}) => {
  let modifiedAllData = allData;
  if (isTreeTableFlag) {
    modifiedAllData = allData?.map((obj) => obj.data);
  }
  const [loading, setLoading] = useState(false);

  const handleLoading = () => {
    setLoading(true);
  };

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
    onSearchClick(); // Call the prop function
  };

  useEffect(() => {
    if (loading) {
      handleExapandCollapseAllButton();
      setLoading(false);
    }
  }, [loading]);
  return (
    <div className="actions" style={{ paddingRight: 42 }}>
      <div className="project-title">{header}</div>

      <div className="action-buttons">
        {projectPlanView && (
          <>
            {loading ? (
              <Loading />
            ) : (
              <>
                <img
                  src={exapandCollapseAll}
                  onClick={() => handleLoading()}
                  data-pr-tooltip="Expand/Collapse All"
                  className={`expand-collapse ${
                    expandedKeys?.length !== 0 ? "invertedIcon" : "nonInvertedIcon"
                  }`}
                  alt=""
                />
                <img
                  hidden={""}
                  alt="filter logo"
                  className="header-icons"
                  onClick={() => removeAllFiltersSortAndFreeze()}
                  src={isGlobalFilterActivated ? BlueFilterIcon : filter}
                />
                <img
                  alt="search field"
                  onClick={handleClick}
                  className="header-icons"
                  src={isClicked ? searchMyProjects : searchMyProjectsClicked}
                />
                <ExportSelectedRows
                  headers={headers}
                  filename={filename}
                  selectedRows={selectedRows}
                  allData={!selectedRows?.lenght && allData}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FilesFolderHeader;
